<template>
  <div class="agreement">
    <Nav />
    <div class="content flex-col">
      <p class="title">可搭吖APP隐私权政策</p>
      <p class="title-desc">发布日期：2024年【7】月【18】日</p>
      <p class="title-desc mb28">生效日期：2024年【7】月【25】日</p>

      <p class="paragraph-title-max">一. 引言</p>
      <p class="paragraph bold">尊敬的用户:</p>
      <p class="paragraph">
        欢迎您使用可搭吖APP(以下简称"本APP"或"可搭吖")。深圳市萨克森计算机有限公司(以下简称"我们")深知个人信息对您的重要性,并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任,恪守以下原则,保护您的个人信息:权责一致原则、目的明确原则、选择同意原则、最少必要原则、确保安全原则、主体参与原则、公开透明原则等。同时,我们承诺,我们将按业界成熟的安全标准,采取相应的安全保护措施来保护您的个人信息。
      </p>
      <p class="paragraph">为了便于您更好地了解您在使用我们的服务时,我们将如何收集、使用、存储、传输与保护您的个人信息,我们特制定本《可搭吖APP隐私权政策》(以下简称"本政策"/"本隐私权政策"),并提醒您:
      </p>
      <p class="paragraph bold">
        本政策是可搭吖APP服务协议不可分割的组成部分,在使用可搭吖APP服务前,请您务必仔细阅读并透彻理解本政策,特别是以粗体/粗体下划线标识的条款,您应重点阅读,在确认充分理解并同意后使用相关产品和服务。如果您不同意本隐私权政策的任何内容,您应立即停止使用我们的服务。如果您是未成年人,请在法定监护人的陪同下阅读本政策,并特别注意未成年人使用条款。
      </p>

      <p class="paragraph-title-max">二. 适用范围</p>
      <p class="paragraph">
        本隐私权政策适用于可搭吖APP提供的所有产品和服务,包括网页端、客户端、移动端等。如某些单项服务有单独的隐私权政策,则该服务的隐私权政策将优先适用;如该服务未设独立隐私权政策,则本隐私权政策适用。</p>
      <p class="paragraph">
        需要特别说明的是,本政策不适用于其他第三方向您提供的服务,也不适用于可搭吖APP中已另行独立设置隐私权政策的产品或服务。例如可搭吖APP上的第三方依托可搭吖APP向您提供服务时,您向第三方提供的个人信息不适用本政策。</p>
      <p class="paragraph bold">请您注意,本政策仅适用于我们所收集的您的个人信息,并不适用于以下情况:</p>
      <p class="paragraph">1. 通过我们的服务而接入的第三方服务(包括任何第三方网站)收集的信息;<br>2. 通过在我们服务中进行广告服务的其他公司和机构所收集的信息。</p>

      <p class="paragraph-title-max">三. 我们如何收集和使用您的个人信息</p>
      <p class="paragraph bold">个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p>
      <p class="paragraph bold">
        个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全,极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息,本隐私权政策中涉及的个人敏感信息包括:个人身份信息(包括身份证)、个人生物识别信息、银行账号、通信内容、健康生理信息、交易信息、精准定位信息、行踪轨迹、14岁以下(含)儿童的个人信息等。
      </p>
      <p class="paragraph">我们根据合法、正当、必要的原则,出于本政策所述的以下目的,收集和使用您在使用服务过程中主动提供或因使用服务而产生的个人信息:</p>
      <p class="paragraph-title">(一)帮助您成为我们的用户</p>
      <p class="paragraph"><span class="paragraph-index">1.1</span> 用户注册</p>
      <p class="paragraph">当您注册成为可搭吖APP的用户时,我们需要收集您的以下信息:</p>
      <p class="paragraph">· 账户信息：您创建可搭吖账户时填写的昵称、头像、性别、出生日期等信息。<br>· <span
          class="bold">手机号码：我们需要收集您的手机号码来验证您的身份、帮助您找回密码或进行其他身份验证服务。</span><br>· <span
          class="bold">身份证信息：根据相关法律法规要求,当您进行实名认证时,我们将收集您的身份证号码等身份证信息。</span></p>
      <p class="paragraph bold">
        请注意,手机号码和身份证号码属于敏感个人信息,这些信息对您的个人身份具有识别作用。若您不提供这些信息,可能无法注册成为我们的用户或无法使用某些功能,但不影响您使用我们提供的基本浏览服务。</p>
      <p class="paragraph"><span class="paragraph-index">1.1</span> 用户登录</p>
      <p class="paragraph">当您登录可搭吖APP时,我们会收集您的登录信息,包括但不限于:</p>
      <p class="paragraph">· 账号和密码<br>· 登录时间<br>· 登录IP地址<br>· 登录设备信息(如设备型号、操作系统版本、设备设置、唯一设备标识符等)</p>
      <p class="paragraph">我们收集这些信息是为了帮助您顺利登录、保障您的账号安全。如您拒绝提供这些信息,您可能无法登录或在使用我们的服务时受到限制。</p>
      <p class="paragraph-title">(二)向您提供产品或服务</p>
      <p class="paragraph"><span class="paragraph-index">2.1</span> 信息浏览和搜索</p>
      <p class="paragraph">当您浏览或搜索我们服务中的信息时，我们会收集您的浏览和搜索记录，包括搜索关键词、访问页面地址、浏览内容和停留时间等信息。我们收集这些信息是为了提升服务体验。</p>
      <p class="paragraph"><span class="paragraph-index">2.2</span> 发布动态</p>
      <p class="paragraph">
        当您在可搭吖APP上发布动态时,我们需要收集您发布的文字、图片、视频、音频等内容。同时,为了支持您使用发布功能,我们可能需要访问您的相册、相机和麦克风权限。您可以在设备的设置功能中查看和管理这些权限。请注意,这些权限均不会默认开启,只有当您主动使用相关功能时才会向您申请授权。
      </p>
      <p class="paragraph"><span class="paragraph-index">2.3</span> 社交功能</p>
      <p class="paragraph">
        当您使用可搭吖APP的社交功能(如关注其他用户、评论、点赞、私信等)时,我们会收集您进行上述操作的日志信息,您发送的文字、图片、视频等内容信息,以及您与其他用户的互动记录。这些信息的收集是实现社交功能所必需的,如您拒绝提供这些信息,您将无法使用相关社交功能,但不影响您使用可搭吖APP的其他功能。
      </p>
      <p class="paragraph"><span class="paragraph-index">2.4</span> 位置服务</p>
      <p class="paragraph">
        当您使用与位置相关的服务时(如附近的人、基于位置的内容推荐等),我们会在您开启设备定位功能并授权我们获取您位置信息的前提下,收集您的位置信息。您可以通过关闭定位功能,停止我们对您位置信息的收集。请注意,即使在您关闭定位功能的情况下,我们仍可能会根据您的IP地址获取您的大致位置信息。
      </p>
      <p class="paragraph"><span class="paragraph-index">2.5</span> 客户服务</p>
      <p class="paragraph">
        当您联系我们的客户服务或提出意见反馈时,我们可能需要您提供姓名、联系方式、问题描述等信息。这些信息是为了帮助我们解决您的问题、改进我们的产品和服务。如果您拒绝提供这些信息,我们可能无法为您提供相应的客户服务。</p>
      <p class="paragraph-title">(三)为您提供安全保障</p>
      <p class="paragraph"><span class="paragraph-index">3.1</span> 安全保障</p>
      <p class="paragraph">
        为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性,保护您或其他用户或公众的人身财产安全免遭侵害,更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险,更准确地识别违反法律法规或可搭吖相关协议规则的情况,我们可能使用或整合您的个人信息、行为信息、设备信息、有关网络日志以及我们关联公司、合作伙伴分享的信息,来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件,并依法采取必要的记录、审计、分析、处置措施。例如,我们会将您的IP地址、设备信息等数据用于判断账号异常状态,预防恶意程序及广告等。
      </p>
      <p class="paragraph"><span class="paragraph-index">3.2</span> 设备权限调用</p>
      <p class="paragraph">为了提供更好的服务和体验,我们可能需要您授权调用您的设备功能,具体包括:</p>
      <p class="paragraph">· 相机/相册权限：用于上传头像、发布图片/视频等<br>· 麦克风权限：用于语音输入、发送语音消息等<br>· 存储权限：用于下载和缓存相关内容<br>·
        位置权限：用于基于位置的服务,如附近的人等<br>· 消息通知：用于推送接受其他用户给你发送的消息等</p>
      <p class="paragraph">
        您可以在设备的设置功能中查看上述权限的状态,并可以选择开启或关闭这些权限。请注意,开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述功能,关闭权限即代表您取消了这些授权,则我们将不再继续收集和使用相关个人信息,也无法为您提供上述与这些权限所对应的功能。
      </p>
      <p class="paragraph-title">(四)其他用途</p>
      <p class="paragraph"><span class="paragraph-index">4.1</span> 征得授权同意的例外</p>
      <p class="paragraph">根据相关法律法规规定,在以下情形中,我们可能会依法收集并使用您的个人信息无需征得您的授权同意:</p>
      <p class="paragraph">(1) 与国家安全、国防安全直接相关的;<br>(2) 与公共安全、公共卫生、重大公共利益直接相关的;<br>(3) 与犯罪侦查、起诉、审判和判决执行等直接相关的;<br>(4)
        出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;<br>(5) 所收集的个人信息是您自行向社会公众公开的;<br>(6)
        从合法公开披露的信息中收集个人信息的,如合法的新闻报道、政府信息公开等渠道;<br>(7) 根据您的要求签订和履行合同所必需的;<br>(8)
        用于维护所提供的产品或服务的安全稳定运行所必需的,例如发现、处置产品或服务的故障;<br>(9) 法律法规规定的其他情形。</p>
      <p class="paragraph">
        请注意,如果信息无法单独或结合其他信息识别到您的个人身份,其不属于法律意义上您的个人信息;当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时,这些信息在结合使用期间将作为您的个人信息按照本隐私权政策处理与保护。
      </p>

      <p class="paragraph-title-max">四. 我们如何使用Cookie和同类技术</p>
      <p class="paragraph">Cookie
        和同类技术是互联网中的通用常用技术。当您使用可搭吖APP及相关服务时,我们可能会使用相关技术向您的设备发送一个或多个Cookie或匿名标识符,以收集和存储您访问、使用本产品时的信息。我们承诺,不会将Cookie用于本隐私政策所述目的之外的任何其他用途。我们使用Cookie和同类技术主要为了:
      </p>
      <p class="paragraph">· 记住您的身份。例如:Cookie有助于我们辨认您作为我们的注册用户的身份,或保存您向我们提供的有关您的喜好或其他信息;<br>·
        分析您使用我们服务的情况。我们可利用Cookie来了解您使用我们的服务进行什么活动、或哪些网页或服务最受您的欢迎;<br>· 广告优化。Cookie有助于我们根据您的信息,向您提供与您相关的广告而非进行普遍的广告投放。
      </p>
      <p class="paragraph">您可以通过浏览器设置拒绝或管理Cookie。但请注意,如果停用Cookie,您可能无法享受最佳的服务体验,某些服务也可能无法正常使用。同时,我们还会通过Local
        Storage等浏览器缓存技术在您的设备上存储数据。您也可以通过浏览器设置清除这些数据。</p>

      <p class="paragraph-title-max">五. 我们如何共享、转让、公开披露您的个人信息</p>
      <p class="paragraph"><span class="paragraph-index">5.1</span> 共享</p>
      <p class="paragraph">我们不会与可搭吖服务提供者以外的公司、组织和个人分享您的个人信息,但以下情况除外:</p>
      <p class="paragraph">(1) 在获取明确同意的情况下共享:获得您的明确同意后,我们会与其他方共享您的个人信息。<br>(2)
        在法定情形下的共享:我们可能会根据法律法规规定、诉讼争议解决需要,或按行政、司法机关依法提出的要求,对外共享您的个人信息。<br>(3)
        与授权合作伙伴共享:仅为实现本隐私权政策中声明的目的,我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息,以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息,并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
      </p>
      <p class="paragraph">目前,我们的授权合作伙伴包括以下类型:</p>
      <p class="paragraph">·
        广告、分析服务类的授权合作伙伴。除非得到您的许可,否则我们不会将您的个人身份信息(指可以识别您身份的信息,例如姓名或电子邮箱,通过这些信息可以联系到您或识别您的身份)与提供广告、分析服务的合作伙伴共享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息,但不会提供您的个人身份信息,或者我们将这些信息进行去标识化处理,这样它们无法识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合,以进行广告或决策建议。<br>·
        供应商、服务提供商和其他合作伙伴。我们可能将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴,这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。
      </p>
      <p class="paragraph">对我们与之共享个人信息的公司、组织和个人,我们会与其签署严格的保密协定,要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
      <p class="paragraph"><span class="paragraph-index">5.2</span> 转让</p>
      <p class="paragraph">我们不会将您的个人信息转让给任何公司、组织和个人,但以下情况除外:</p>
      <p class="paragraph">(1) 在获取明确同意的情况下转让:获得您的明确同意后,我们会向其他方转让您的个人信息;<br>(2)
        在涉及合并、收购或破产清算时,如涉及到个人信息转让,我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束,否则我们将要求该公司、组织重新向您征求授权同意。</p>
      <p class="paragraph"><span class="paragraph-index">5.3</span> 公开披露</p>
      <p class="paragraph">我们仅会在以下情况下,公开披露您的个人信息:</p>
      <p class="paragraph">(1) 获得您明确同意后;<br>(2) 基于法律的披露:在法律、法律程序、诉讼或政府主管部门强制性要求的情况下,我们可能会公开披露您的个人信息。</p>
      <p class="paragraph"><span class="paragraph-index">5.4</span> 共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
      <p class="paragraph">以下情形中,共享、转让、公开披露您的个人信息无需事先征得您的授权同意:</p>
      <p class="paragraph">(1) 与国家安全、国防安全直接相关的;<br>(2) 与公共安全、公共卫生、重大公共利益直接相关的;<br>(3) 与犯罪侦查、起诉、审判和判决执行等直接相关的;<br>(4)
        出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;<br>(5) 您自行向社会公众公开的个人信息;<br>(6) 从合法公开披露的信息中收集个人信息的,如合法的新闻报道、政府信息公开等渠道。</p>
      <p class="paragraph">
        请注意,根据适用的法律,若我们对个人信息采取技术措施和其他必要措施进行处理,使得数据接收方无法重新识别特定个人且不能复原,则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。</p>

      <p class="paragraph-title-max">六. 我们如何保护和保存您的个人信息</p>
      <p class="paragraph"><span class="paragraph-index">6.1</span> 我们保护您个人信息的技术与措施</p>
      <p class="paragraph">我们非常重视个人信息安全,并采取一切合理可行的措施,保护您的个人信息:</p>
      <p class="paragraph">(1) 数据安全技术措施</p>
      <p class="paragraph">我们会采用符合业界标准的安全防护措施,包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。</p>
      <p class="paragraph">网络安全层面:我们采取了加密技术对数据进行加密存储,并通过隔离技术进行隔离。在数据使用时,例如数据传输,鉴权,访问,我们会采用多种数据安全技术保护数据,如HTTPS、Secure
        Socket Layer加密传输、加密存储、严格的数据访问权限控制和多重身份认证技术。</p>
      <p class="paragraph">(2) 我们为保护个人信息采取的其他安全措施</p>
      <p class="paragraph">我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。</p>
      <p class="paragraph">我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</p>
      <p class="paragraph">加强安全意识。我们还会举办安全和隐私保护培训课程,加强员工对于保护个人信息重要性的认识。</p>
      <p class="paragraph">(3)
        我们仅允许有必要知晓这些信息的可搭吖及可搭吖关联方的员工、合作伙伴访问个人信息,并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务,可能会被追究法律责任或被中止与可搭吖的合作关系。
      </p>
      <p class="paragraph">(4) 我们会采取一切合理可行的措施,确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息,除非需要延长保留期或受到法律的允许。</p>
      <p class="paragraph">(5)
        互联网并非绝对安全的环境,而且电子邮件、即时通讯、社交软件或其他服务软件等与其他用户的交流方式无法确定是否完全加密,我们建议您使用此类工具时请使用复杂密码,并注意保护您的个人信息安全。</p>
      <p class="paragraph">(6)
        互联网环境并非百分之百安全,我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏,导致信息被非授权访问、公开披露、篡改、或毁坏,导致您的合法权益受损,我们将承担相应的法律责任。
      </p>
      <p class="paragraph"><span class="paragraph-index">6.2</span> 您个人信息的保存</p>
      <p class="paragraph">(1) 您的个人信息将全被存储于中华人民共和国境内。如果我们需要向境外传输您的个人信息,我们会征得您的单独同意,并向您告知数据出境的目的、接收方、安全保证措施和安全风险。</p>
      <p class="paragraph">(2) 原则上,我们在中华人民共和国境内收集和产生的个人信息,将存储在中华人民共和国境内。以下情形除外:</p>
      <p class="paragraph">· 法律法规有明确规定;<br>· 获得您的明确授权;<br>· 您通过互联网进行跨境交易等个人主动行为。</p>
      <p class="paragraph">针对以上情形,我们会确保依据本隐私政策对您的个人信息提供足够的保护。</p>
      <p class="paragraph">(3) 我们只会在为实现本政策所述目的所必需的期间内保留您的个人信息,除非法律有强制的存留要求。我们判断前述期限的标准包括:</p>
      <p class="paragraph">· 完成与您相关的交易目的、维护相应交易及业务记录、应对可能的查询或投诉;<br>· 保证我们为您提供服务的安全和质量;<br>· 您是否同意更长的留存期间;<br>·
        是否存在保留期限的其他特别约定。</p>
      <p class="paragraph">在超出保留期间后,我们会根据适用法律的要求删除您的个人信息,或使其匿名化处理。</p>
      <p class="paragraph"><span class="paragraph-index">6.3</span> 如发生个人信息安全事件</p>
      <p class="paragraph">
        如不幸发生个人信息安全事件,我们将按照法律法规的要求,及时向您告知:安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您,难以逐一告知个人信息主体时,我们会采取合理、有效的方式发布公告。同时,我们还将按照监管部门要求,主动上报个人信息安全事件的处置情况。
      </p>

      <p class="paragraph-title-max">七. 您如何管理您的个人信息</p>
      <p class="paragraph">按照中国相关的法律、法规、标准,以及其他国家、地区的通行做法,我们保障您对自己的个人信息行使以下权利:</p>
      <p class="paragraph"><span class="paragraph-index">7.1</span> 访问和更正您的个人信息</p>
      <p class="paragraph">除法律法规规定外,您有权访问和更正您的个人信息。您可以通过以下方式自行操作:</p>
      <p class="paragraph">· 账户信息：您可以在可搭吖APP中访问或更正您的账户信息,如修改头像、昵称等。<br>· 搜索记录：您可以在APP中查看或清除您的搜索历史记录。<br>·
        设备权限：您可以在设备的设置功能中选择开启或关闭相应的设备权限。</p>
      <p class="paragraph">如果您无法通过上述方式访问或更正这些个人信息,您可以随时通过可搭吖客服或发送邮件至<a class="paragraph-btn"
          href="mailto:support@psyduck.com.cn">support@psyduck.com.cn</a>与我们联系。我们将在30天内回复您的请求。</p>
      <p class="paragraph"><span class="paragraph-index">7.2</span> 删除您的个人信息</p>
      <p class="paragraph">在以下情形中,您可以向我们提出删除个人信息的请求:</p>
      <p class="paragraph">1. 如果我们处理个人信息的行为违反法律法规;<br>2. 如果我们收集、使用您的个人信息,却未征得您的明确同意;<br>3.
        如果我们处理个人信息的行为严重违反了与您的约定;<br>4. 如果您不再使用我们的产品或服务,或您主动注销了账号;<br>5. 如果我们永久不再为您提供产品或服务。</p>
      <p class="paragraph">若我们决定响应您的删除请求,我们还将同时尽可能通知从我们获得您的个人信息的主体,要求其及时删除,除非法律法规另有规定,或这些主体获得您的独立授权。</p>
      <p class="paragraph">当您从我们的服务中删除信息后,我们可能不会立即从备份系统中删除相应的信息,但会在备份更新时删除这些信息。</p>
      <p class="paragraph"><span class="paragraph-index">7.3</span> 改变您授权同意的范围</p>
      <p class="paragraph">每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用,您可以随时给予或收回您的授权同意。您可以通过以下方式自行操作:</p>
      <p class="paragraph">· 在设备权限设置中更改同意范围或收回授权。<br>· 在APP内的隐私设置中更改您授权我们继续收集个人信息的范围或收回授权。</p>
      <p class="paragraph">当您收回同意后,我们将不再处理相应的个人信息。但您收回同意的决定,不会影响此前基于您的授权而开展的个人信息处理。</p>
      <p class="paragraph"><span class="paragraph-index">7.4</span> 响应您的上述请求</p>
      <p class="paragraph">您可以通过以下方式注销账户:</p>
      <p class="paragraph">1. 在可搭吖APP中选择"设置"-"账号与安全"-"注销账号"。<br>2. 联系可搭吖客服申请注销账号。</p>
      <p class="paragraph">在注销账户之后,我们将停止为您提供产品或服务,并依据您的要求,删除您的个人信息,法律法规另有规定的除外。</p>
      <p class="paragraph"><span class="paragraph-index">7.5</span> 注销账户</p>
      <p class="paragraph">为保障安全,您可能需要提供书面请求,或以其他方式证明您的身份。我们可能会先要求您验证自己的身份,然后再处理您的请求。</p>
      <p class="paragraph">我们将在30天内做出答复。如您不满意,还可以通过客服发起投诉。</p>
      <p class="paragraph">
        对于您合理的请求,我们原则上不收取费用,但对多次重复、超出合理限度的请求,我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段(例如,需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际的请求,我们可能会予以拒绝。
      </p>
      <p class="paragraph">在以下情形中,按照法律法规要求,我们将无法响应您的请求:</p>
      <p class="paragraph">1. 与国家安全、国防安全直接相关的;<br>2. 与公共安全、公共卫生、重大公共利益直接相关的;<br>3. 与犯罪侦查、起诉、审判和执行判决等直接相关的;<br>4.
        有充分证据表明您存在主观恶意或滥用权利的;<br>5. 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的;<br>6. 涉及商业秘密的。</p>

      <p class="paragraph-title-max">八. 未成年人的个人信息保护</p>
      <p class="paragraph">我们非常重视对未成年人个人信息的保护。根据相关法律法规的规定,若您是18周岁以下的未成年人,在使用可搭吖的服务前,应事先取得您的家⻓或法定监护人的书面同意。</p>
      <p class="paragraph">我们只会在受到法律允许、家⻓或监护人明确同意或者保护未成年人所必要的情况下收集、使用、储存、共享、转让或披露未成年人的个人信息。</p>
      <p class="paragraph">如果我们发现自己在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息,则会设法尽快删除相关数据。</p>

      <p class="paragraph-title-max">九. 本隐私权政策如何更新</p>
      <p class="paragraph">
        我们可能适时会对本隐私权政策进行调整或变更,本隐私权政策的任何更新将以标注更新时间的方式公布在可搭吖APP上。未经您明确同意,我们不会削减您按照本隐私权政策所应享有的权利。对于重大变更,我们还会提供更为显著的通知(我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式,说明隐私权政策的具体变更内容)。
      </p>
      <p class="paragraph">本政策所指的重大变更包括但不限于:</p>
      <p class="paragraph">1. 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等;<br>2.
        我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等;<br>3. 个人信息共享、转让或公开披露的主要对象发生变化;<br>4.
        您参与个人信息处理方面的权利及其行使方式发生重大变化;<br>5. 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时;<br>6. 个人信息安全影响评估报告表明存在高风险时。</p>
      <p class="paragraph">我们还会将本隐私权政策的旧版本存档,供您查阅。</p>

      <p class="paragraph-title-max">十. 如何联系我们</p>
      <p class="paragraph">如您对本隐私权政策或您个人信息的相关事宜有任何问题、意⻅或建议,请通过以下方式与我们联系:</p>
      <p class="paragraph">电子邮箱 <a class="paragraph-btn" href="mailto:support@psyduck.com.cn">support@psyduck.com.cn</a>
      </p>
      <p class="paragraph">一般情况下,我们将在30天内回复。如果您对我们的回复不满意,特别是我们的个人信息处理行为损害了您的合法权益,您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。</p>

      <p class="paragraph-title-max">十一. 第三方提供的软件开发包(SDK)说明</p>
      <p class="paragraph">
        为了向您提供更全面和优质的服务,我们的应用中可能会包含第三方提供的软件开发工具包(SDK)。我们谨慎评估这些SDK收集使用个人信息的情况,仅会接入确有必要的SDK。我们会要求这些SDK服务商严格遵守我们的数据隐私和安全要求,包括但不限于采取严格的数据保护措施,不得将这些信息用于与其提供服务无关的目的。
      </p>
      <p class="paragraph">以下是我们接入的主要第三方SDK及其功能说明:</p>
      <p class="paragraph"><span class="paragraph-index">1.</span> 微信开放平台SDK</p>
      <p class="paragraph">· SDK名称：微信OpenSDK<br>· 开发者：深圳市腾讯计算机系统有限公司<br>· 使用目的：实现微信登录、分享功能<br>·
        收集个⼈信息类型：设备信息(IMEI、MAC地址等)、微信⽤户标识<br>·
        隐私政策链接：<a class="paragraph-btn"
          href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8">https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8</a>
      </p>
      <p class="paragraph"><span class="paragraph-index">2.</span> 友盟+SDK</p>
      <p class="paragraph">· SDK名称：友盟+移动统计SDK<br>· 开发者：友盟同欣（北京）科技有限公司<br>· 使⽤⽬的：进⾏APP运营统计与分析<br>·
        收集个⼈信息类型：设备信息（IMEI/MAC/Android ID/IDFA/OAID/OpenUDID/GUID/SIM卡
        IMSI/ICCID）、位置信息、⽹络信息<br>· 隐私权政策链接：<a class="paragraph-btn"
          href="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a></p>
      <p class="paragraph"><span class="paragraph-index">3.</span> ⾼德开放平台地图SDK（合包）</p>
      <p class="paragraph">· SDK名称：定位SDK<br>· 开发者：⾼德软件有限公司<br>· 使⽤⽬的：为了实现地图展示、定位、搜索等服务<br>· 收集个⼈信息类型：经纬度、设备信息【如IP
        地址、GNSS信息、⽹络类型、WiFi状态、WiFi参数、
        WiFi列表、SSID、BSSID、基站信息、WiFi信号强度的信息传感器信息（⽮量、加速度、压⼒、⽅
        向、地磁）、设备信号强度信息】、设备标识信息（IDFA、OAID）、当前应⽤信息（应⽤名、应⽤
        版本号）、设备参数及系统信息（设备品牌及型号、操作系统、运营商信息、屏幕分辨率）<br>·
        隐私政策链接：<a class="paragraph-btn"
          href="https://lbs.amap.com/pages/privacy/">https://lbs.amap.com/pages/privacy/</a></p>
      <p class="paragraph"><span class="paragraph-index">4.</span> 性能加速库</p>
      <p class="paragraph">
        · SDK名称：多线程库、性能加速库<br>
        · 开发者：华为软件技术有限公司<br>
        · 使⽤⽬的：提供的多线程编程框架和系统性能信息感知，优化APP交互体验<br>
        · 收集个⼈信息类型：不涉及<br>
        · 隐私政策链接：<a class="paragraph-btn"
          href="https://developer.huawei.com/consumer/cn/doc/graphics-Guides/sdk-data-security-0000001050700772">https://developer.huawei.com/consumer/cn/doc/graphics-Guides/sdk-data-security-0000001050700772</a>
      </p>
      <p class="paragraph"><span class="paragraph-index">5.</span> 灯塔 SDK</p>
      <p class="paragraph">
        · SDK名称：灯塔 SDK<br>
        · 开发者：深圳市腾讯计算机系统有限公司<br>
        · 使⽤⽬的：不涉及 （微信OpenSDK收集<br>
        · 收集个⼈信息类型：不涉及<br>
        · 隐私政策链接：<a class="paragraph-btn"
          href="https://privacy.qq.com/document/preview/a19a8cf4f2354306b137a7db39d32024">https://privacy.qq.com/document/preview/a19a8cf4f2354306b137a7db39d32024</a>
      </p>
      <p class="paragraph"><span class="paragraph-index">6.</span> vivo推送SDK</p>
      <p class="paragraph">
        · SDK名称：vivo推送SDK<br>
        · 开发者：维沃移动通信有限公司<br>
        · 使⽤⽬的：⽤于vivo⼿机的消息推送<br>
        · 收集个⼈信息类型：应⽤基本信息、应⽤内设备标识符、设备的硬件信息、系统基本信息<br>
        · 隐私政策链接：<a class="paragraph-btn"
          href="https://dev.vivo.com.cn/documentCenter/doc/706">https://dev.vivo.com.cn/documentCenter/doc/706</a>
      </p>
      <p class="paragraph"><span class="paragraph-index">7.</span> 华为推送SDK（Android版）</p>
      <p class="paragraph">
        · SDK名称：华为推送SDK（Android版）<br>
        · 开发者：华为软件技术有限公司<br>
        · 使⽤⽬的：⽤于华为⼿机的消息推送<br>
        · 收集个⼈信息类型：应⽤信息（应⽤基本信息），设备信息（AAID、设备标识符、设备硬件信息、
        系统基本信息、系统设置<br>
        · 隐私政策链接：<a class="paragraph-btn"
          href="https://developer.huawei.com/consumer/cn/doc/HMSCore-Guides/sdk-data-security-0000001050042177">https://developer.huawei.com/consumer/cn/doc/HMSCore-Guides/sdk-data-security-0000001050042177</a>
      </p>
      <p class="paragraph"><span class="paragraph-index">8.</span> oppo消息推送K</p>
      <p class="paragraph">
        · SDK名称：OPUSH SDK<br>
        · 开发者：⼴东欢太科技有限公司<br>
        · 使⽤⽬的：⽤于oppo⼿机的消息推送<br>
        · 收集个⼈信息类型：应⽤基本信息（MCS应⽤包名、应⽤版本号、OPUSH SDK版本号），应⽤内
        设备标识符（RegistraterID、appKey、appSecret）<br>
        · 隐私政策链接：<a class="paragraph-btn"
          href="https://open.oppomobile.com/new/developmentDoc/info?id=11228">https://open.oppomobile.com/new/developmentDoc/info?id=11228</a>
      </p>
      <p class="paragraph"><span class="paragraph-index">9.</span> 荣耀推送SDK</p>
      <p class="paragraph">
        · SDK名称：荣耀推送SDK<br>
        · 开发者：深圳荣耀软件技术有限公司<br>
        · 使⽤⽬的：⽤于荣耀⼿机的消息推送<br>
        · 处理的个⼈信息类型：设备标识符（AAID、PushToken）<br>
        · 隐私政策链接：<a class="paragraph-btn"
          href="https://developer.honor.com/cn/docs/11002/guides/sdk-data-security">https://developer.honor.com/cn/docs/11002/guides/sdk-data-security</a>
      </p>
      <p class="paragraph"><span class="paragraph-index">10.</span> ⼩⽶推送SDK</p>
      <p class="paragraph">
        · SDK名称：⼩⽶推送SDK<br>
        · 开发者：北京⼩⽶移动软件有限公司<br>
        · 使⽤⽬的：⽤于⼩⽶⼿机的消息推送<br>
        · 收集个⼈信息类型：设备标识(OAID、加密的Android ID)、推送消息内容、设备信息(设备⼚商、型
        号、归属地、运营商名称等)、通知栏设置<br>
        · 可能调⽤的权限：访问⽹络状态、获取设备信息、写⼊设备存储<br>
        · 隐私政策链接：<a class="paragraph-btn"
          href="https://dev.mi.com/console/doc/detail?pId=1822">https://dev.mi.com/console/doc/detail?pId=1822</a>
      </p>
      <p class="paragraph">我们会定期评估这些第三方SDK的个人信息收集与使用情况。如果发现第三方SDK超出约定范围收集、使用个人信息,或未能有效保护您的个人信息,我们会要求其整改或者停止合作。</p>
      <p class="paragraph">
        您可以通过阅读这些第三方的隐私政策来进一步了解他们如何处理您的个人信息以及您所享有的权利。如果您对某个具体的SDK存在疑问或者希望行使相关的权利,您可以直接与其联系或者通过我们的客服与我们联系。</p>
      <p class="paragraph">请注意,第三方SDK可能会不时更新他们的隐私政策。我们会密切关注这些变化,并在发现重大变更时及时更新本政策中的相关信息。建议您定期查看本政策以及相关的第三方隐私政策。</p>

      <p class="paragraph-title-max">附录</p>
      <p class="paragraph-title">本隐私权政策中的关键词说明</p>
      <p class="paragraph">1. 个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。<br>2.
        个人敏感信息：指一旦泄露、非法提供或滥用可能危害人身和财产安全,极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。<br>3.
        设备：指可用于访问可搭吖服务的装置,例如台式计算机、笔记本电脑、平板电脑或智能手机。<br>4.
        唯一设备标识符：指具有唯一性的设备标识符(有时称为通用唯一识别码或UUID),是指由设备制造商编入到设备中的一串字符,可用于以独有方式标识相应设备(例如手机的IMEI号)。<br>5.
        IP地址：每台上网的设备都会指定一个编号,称为互联网协议(IP)地址。这些编号通常都是根据地理区域指定的。IP地址通常可用于识别设备连接至互联网时所在的位置。<br>6. SSL：SSL(Secure Sockets
        Layer 安全套接层)是一种安全协议,目的是为互联网通信提供安全及数据完整性保障。<br>7. Cookie：是网站服务器放在用户浏览器(如IE、chrome)上的一小段文本文件。<br>8.
        匿名化：通过对个人信息的技术处理,使得个人信息主体无法被识别,且处理后的信息不能被复原的过程。<br>9. 去标识化：通过对个人信息的技术处理,使其在不借助额外信息的情况下,无法识别个人信息主体的过程。</p>
      <p class="paragraph-title">
        特别提示：本隐私权政策仅适用于可搭吖APP提供的服务。您使用该服务时,可能会链接到其他网站或APP。请注意,这些网站或APP可能有其各自的隐私政策,我们建议您在向其提供个人信息之前先行阅读并了解其隐私政策。</p>
      <p class="paragraph">本隐私权政策的解释权归深圳市萨克森计算机有限公司所有。</p>
    </div>
    <goTop />
    <Footer ref="footer"></Footer>
  </div>
</template>

<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
import goTop from './components/goTop.vue'
export default {
  name: "Policy",
  components: { Nav, Footer, goTop },
  data() {
    return {
    };
  },
  mounted() {

  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.agreement {
  .content {
    width: 1280rem;
    margin: 0 auto;
    padding: 148rem 0 168rem 0;

    .title {
      width: 100%;
      text-align: center;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      font-size: 36rem;
      color: #0B162B;
      line-height: 42rem;
      text-align: center;
      font-style: normal;
      text-transform: none;
      margin-bottom: 80rem;
    }

    .title-desc,
    .paragraph {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      font-size: 20rem;
      color: #666666;
      line-height: 34rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .paragraph {
      margin-bottom: 20rem;

      .paragraph-index {
        color: #333333;
      }

      .paragraph-btn {
        color: #0366d6;
        text-decoration: underline;
        word-break: break-all;
      }
    }

    .paragraph-title {
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      font-size: 20rem;
      color: #333333;
      line-height: 30rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-bottom: 10rem;
    }

    .paragraph-title-max {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 20rem;
      color: #333333;
      line-height: 22rem;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-top: 20rem;
      margin-bottom: 20rem;
    }

    .bold {
      font-weight: bold;
      color: #333333
    }
  }
}
</style>
